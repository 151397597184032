import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import React, {useEffect, useRef, useState} from 'react'
import {Breadcrumbs, Card, Chip, CircularProgress, CssBaseline, CssVarsProvider, Link} from "@mui/joy";
import Box from "@mui/joy/Box";
import Header from "../dashboard-components/Header";
import SideNav from "../dashboard-components/SideNav";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {GetAllBookings, UpdateBookingTime} from "../../axios/Bookings-Axios";
import {CalendarApi, EventInput} from "fullcalendar";
import BookingsModal from "../modals/BookingsModal";
import Tooltip from "@mui/joy/Tooltip";
import {DragHandle, LocalActivity} from "@mui/icons-material";
import Sheet from "@mui/joy/Sheet";
import Button from "@mui/joy/Button";
import RotaModal from "../modals/RotaModal";
import RecurringBookingsModal from "../modals/RecurringBookingsModal";


const formatServices = (services: Service[]): string => {
    const serviceNames = services.map(service => service.name);

    if (serviceNames.length === 0) {
        return "No service selected.";
    } else if (serviceNames.length === 1) {
        return serviceNames[0];
    } else if (serviceNames.length === 2) {
        return `${serviceNames[0]} and ${serviceNames[1]}`;
    } else {
        const lastService = serviceNames.pop();
        return `${serviceNames.join(', ')}, and ${lastService}`;
    }
}


export default function Calendar() {

    const [bookings, setBookings] = React.useState <Booking[]>([]);
    const ref = React.useRef<FullCalendar | undefined>();
    const calendarLoading = React.useRef<HTMLDivElement | null>(null);

    const [openRecurringBookingId, setOpenRecurringBookingId] = React.useState(0);
    const [openBookingId, setOpenBookingId] = React.useState(0);
    const [openBookingModal, setOpenBookingModal] = React.useState(false);
    const [openRecurringBookingModal, setOpenRecurringBookingModal] = React.useState(false);
    const [openRotaModal, setOpenRotaModal] = React.useState(false);


    const fetchBookingsForCalendar = async (fetchInfo, successCallback, failureCallback) => {
        try {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_');
            const {start, end} = fetchInfo;

            let loadedBookings = await GetAllBookings([location], -1, '', null, null, null, start, end);
            const events = loadedBookings
                .filter(booking => booking.booked_in !== null)
                .map(booking => ({
                    editable: !booking.recurring_booking,
                    id: String(booking.id) || 'Unknown',
                    title: booking.customer?.full_name || 'Unknown',
                    start: new Date(booking.booked_in),
                    end: booking.booked_in ? new Date(booking.booked_in) : undefined,
                    extendedProps: {
                        recurring_booking: booking.recurring_booking ? true : false,
                        recurring_booking_id: booking.recurring_booking,
                        services: formatServices(booking.services)
                    }
                }));

            successCallback(events);
        } catch (error) {
            failureCallback(error);
        }
    };

    let handleCallback = () => {
        setOpenBookingModal(false)
        setOpenRotaModal(false)
        setOpenRecurringBookingModal(false)
    };


    const handleEventClick = (info) => {
        let bookingId = info.event.id;
        if (info.event._def.extendedProps.recurring_booking) {
            setOpenRecurringBookingId(info.event._def.extendedProps.recurring_booking_id.id)
            setOpenRecurringBookingModal(true)
        } else {
            setOpenBookingId(bookingId)
            setOpenBookingModal(true)
        }

    };

    const handleRotaModalClick = () => {
        setOpenRotaModal(true)
    };

    const handleEventDrop = (info) => {
        let newDate = info.event.start.toISOString();
        let bookingId = info.event.id;

        UpdateBookingTime(bookingId, newDate)
    };


    const renderEventContent = (eventInfo) => {
        const servicesText = eventInfo.event.extendedProps.services;
        return (
            <Box>
                <Tooltip
                    title={servicesText}
                    arrow
                    placement="right"
                >
                    <Chip color={eventInfo.event.extendedProps.recurring_booking ? "warning" : "primary"}
                          startDecorator={!eventInfo.event.extendedProps.recurring_booking && <DragHandle/>}
                          size="lg"
                    >{eventInfo.timeText} - {eventInfo.event.title}</Chip></Tooltip>
            </Box>
        );
    };


    return (

        <CssVarsProvider disableTransitionOnChange>
            <React.Fragment>
                <RotaModal parentCallback={handleCallback} openState={openRotaModal}></RotaModal>
            </React.Fragment>
            <React.Fragment>
                <BookingsModal key={'bookings-modal-' + openBookingModal} openState={openBookingModal} newBooking={null}
                               newBookingValue={null}
                               bookingId={openBookingId} parentCallback={handleCallback}/>
            </React.Fragment>
            <React.Fragment>
                <RecurringBookingsModal key={'recurring-bookings-modal-' + openRecurringBookingModal}
                                        openState={openRecurringBookingModal}
                                        recurringBookingId={openRecurringBookingId} parentCallback={handleCallback}/>
            </React.Fragment>
            <CssBaseline/>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <SideNav/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {xs: 2, md: 6},
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                underline="none"
                                color="neutral"
                                href="#some-link"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="#some-link"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Dashboard
                            </Link>
                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Job Calendar
                            </Typography>

                        </Breadcrumbs>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            mb: 1,
                            gap: 1,
                            flexDirection: {xs: 'column', sm: 'row'},
                            alignItems: {xs: 'start', sm: 'center'},
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            <Typography level="h2" component="h1">
                                Job Calendar
                            </Typography>
                            <Button onClick={() => {
                                handleRotaModalClick()
                            }}>Send Tomorrow's Rota</Button>
                        </Box>


                    </Box>

                    <Card variant="outlined" sx={{width: 'fit-content'}}>
                        <Typography>Calendar Key</Typography>
                        <Chip color="primary">Regular Bookings</Chip>
                        <Chip color="warning">Recurring Bookings</Chip>
                    </Card>

                    <Sheet sx={{height: '100%', padding: '25px'}}>
                        <FullCalendar
                            height={'100%'}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            droppable={true}
                            editable={true}
                            eventDurationEditable={false}
                            selectMirror={true}
                            events={(fetchInfo, successCallback, failureCallback) =>
                                fetchBookingsForCalendar(fetchInfo, successCallback, failureCallback)
                            }
                            eventDrop={handleEventDrop}
                            eventClick={handleEventClick}
                            defaultTimedEventDuration={'01:00'}
                            eventTimeFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                meridiem: 'short'
                            }}
                            nextDayThreshold={"09:00:00"}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay'
                            }}
                            eventContent={renderEventContent}
                        />
                    </Sheet>
                </Box>
            </Box>
        </CssVarsProvider>
    )


}

