import React, {useEffect, useState} from "react";
import {
    Card,
    CircularProgress, Dropdown,
    ListItemDecorator, Menu, MenuButton, MenuItem,
    Modal,
    ModalClose,
    ModalDialog,
    Radio,
    RadioGroup,
    Select,
    Textarea
} from "@mui/joy";
import Box from "@mui/joy/Box";
import {
    CreateBooking,
    CreateLead, DeleteLeadById,
    GetAllLeads,
    GetAllServicePrices,
    GetLeadById, UpdateBooking,
    UpdateLead
} from "../../axios/Bookings-Axios";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import {CleanHands, CleaningServices, DeleteForever, Window} from "@mui/icons-material";
import 'react-datepicker/dist/react-datepicker.css';
import Button from "@mui/joy/Button";
import {GetAllFranchises, GetUser} from "../../axios/Axios";
import BookingsModal from "./BookingsModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import CustomersTopComponent from "./CustomersTopComponent";

interface User {
    username: string;
}


function formatDate(old_date) {
    let date = new Date(old_date);
    if (!isNaN(date.getTime())) {
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    } else {
        return 'No Valid Date';
    }
}

export default function LeadsModal({openState, leadId, parentCallback}) {
    const [open, setOpen] = React.useState(openState);
    const [loadedData, setLoadedData] = React.useState(false);
    const [user, setUser] = useState<User>({username: ''});
    const [openBookingModal, setOpenBookingModal] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

    const [customer, setCustomer] = React.useState <Customer>({
        advertisement_platform: undefined,
        created_at: "",
        email: "",
        full_name: "",
        house_type: {name: 'Detached', id: 1},
        id: 0,
        notes: "",
        phone: "",
        postcode: "",
        street_address: ""
    });
    const [lead, setLead] = React.useState <Lead>({
        created_at: "",
        customer: customer,
        id: -1,
        notes: undefined,
        paid: false,
        services: [],
        service_price: [],
        status: []
    });
    const [leadValue, setLeadValue] = React.useState <ServiceValue>({
        conservatoryGutterCleanPrice: 0, gutterCleanPrice: 0, windowCleanPrice: 0,
        conservatoryGutterClean: false,
        gutterClean: false,
        price: 0,
        windowClean: false
    })

    useEffect(() => {
        const fetchData = async () => {
            const userResponse = await GetUser();
            setUser(userResponse.data);
        };
        fetchData()
    }, []);

    useEffect(() => {
        setLead(prevLead => ({
            ...(prevLead || {}),
            customer: customer
        }));
    }, [customer]);

    useEffect(() => {
        const newPrice = (leadValue.windowClean ? Number(leadValue.windowCleanPrice) : 0) +
            (leadValue.gutterClean ? Number(leadValue.gutterCleanPrice) : 0) +
            (leadValue.conservatoryGutterClean ? Number(leadValue.conservatoryGutterCleanPrice) : 0);


        setLeadValue(leadValue => ({
            ...leadValue,
            price: newPrice
        }));
    }, [leadValue.windowClean, leadValue.gutterClean, leadValue.conservatoryGutterClean, leadValue.windowCleanPrice, leadValue.gutterCleanPrice, leadValue.conservatoryGutterCleanPrice]);


    const handleLeadValueInputChange = (event) => {
        const {name, value} = event.target;
        setLeadValue(prevLead => ({
            ...prevLead,
            [name]: value,
        }));
    };

    useEffect(() => {
        setLead(prevLead => ({
            ...prevLead,
            customer: customer,
        }));
    }, [customer]);

    function setStatus(status) {
        let prevStatus = lead.status;
        prevStatus.push({id: -1, status: status, created_at: new Date().toISOString(), user: user.username})
        setLead(prevLead => ({
            ...prevLead,
            status: prevStatus,
        }));
    }

    function convertToBooking() {
        const updateLead = async () => {
            if (lead.id !== -1) {
                let createdLead = await UpdateLead(leadId, lead, leadValue);
            } else {
                let createdLead = await CreateLead(lead, leadValue);
            }
        }
        updateLead()
        setOpen(false)
        setOpenBookingModal(true)

    }


    useEffect(() => {
        if (leadId == 0) {
            return;
        }
        setLoadedData(false)
        const fetchData = async () => {
            const servicePriceTier = await GetAllServicePrices('D', 'Detached');
            if (leadId == -1) {
                const defaultWindowCleanPrice = servicePriceTier[0].window_clean_price;

                const defaultConservatoryGutterCleanPrice = servicePriceTier[0].conservatory_gutter_clean_price;

                const defaultGutterCleanPrice = servicePriceTier[0].gutter_clean_price;

                setLeadValue(prevLead => ({
                    ...prevLead,
                    conservatoryGutterCleanPrice: defaultConservatoryGutterCleanPrice,
                    gutterCleanPrice: defaultGutterCleanPrice,
                    windowCleanPrice: defaultWindowCleanPrice,
                }));

            } else {

                let leads = await GetLeadById(leadId);
                if (leads['customer']['notes'] == null) {
                    leads['customer']['notes'] = ''
                }
                setCustomer(leads['customer'])
                setLead(leads)
                const defaultWindowCleanPrice = leads?.customer?.franchise_postcode?.service_price_tier?.window_clean_price
                    || servicePriceTier[0].window_clean_price;

                const defaultConservatoryGutterCleanPrice = leads?.customer?.franchise_postcode?.service_price_tier?.conservatory_gutter_clean_price
                    || servicePriceTier[0].conservatory_gutter_clean_price;

                const defaultGutterCleanPrice = leads?.customer?.franchise_postcode?.service_price_tier?.gutter_clean_price
                    || servicePriceTier[0].gutter_clean_price;

                setLeadValue(prevLead => ({
                    ...prevLead,
                    gutterClean: leads.services.some(service => service.name === "Gutter Clean"),
                    windowClean: leads.services.some(service => service.name === "Window Clean"),
                    conservatoryGutterClean: leads.services.some(service => service.name === "Conservatory Gutter Clean"),
                    conservatoryGutterCleanPrice: leads.service_price?.find(item => item.service.name === "Conservatory Gutter Clean")?.price || defaultConservatoryGutterCleanPrice,
                    gutterCleanPrice: leads.service_price?.find(item => item.service.name === "Gutter Clean")?.price || defaultGutterCleanPrice,
                    windowCleanPrice: leads.service_price?.find(item => item.service.name === "Window Clean")?.price || defaultWindowCleanPrice,
                }));

            }

            setLoadedData(true)
        };
        fetchData()
    }, []);


    function closeModal() {
        setOpen(false)
        parentCallback();
    }

    const handleSave = async (event) => {
        event.preventDefault()
        const updateSavingLead = async () => {
            if (lead.id !== -1) {
                let updatedLead = await UpdateLead(leadId, lead, leadValue);
                return updatedLead
            } else {
                let createdLead = await CreateLead(lead, leadValue);
                return createdLead
            }
        };

        await updateSavingLead();
        window.location.reload(false);
    };


    let handleCallback = () => {
        setOpenBookingModal(false)
    };


    let handleDeleteCallBack = async (doDelete) => {
        setOpenDeleteModal(false)
        if (!doDelete) {
            return;
        }
        const deleteLead = async () => {
            return await DeleteLeadById(leadId);
        }
        await deleteLead();
        window.location.reload(false);
    };

    return (
        <>
            <Modal slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'none',
                        backgroundColor: 'rgb(108 108 108 / 60%)',
                        padding: '10px'
                    },
                },
            }} open={open} onClose={() => closeModal()}>
                <ModalDialog>
                    <form onSubmit={handleSave} id="a-form">
                        <ModalClose/>
                        <Box sx={{
                            minWidth: 600,
                            display: 'flex',
                            flexDirection: 'column',
                            overflowX: 'hidden',
                        }}>
                        </Box>

                        {!loadedData ?
                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                minWidth: '50%',
                                height: '100%',
                                justifyContent: 'center'
                            }}>
                                <CircularProgress
                                    size="lg"
                                />
                            </Box>
                            :
                            <>
                                <Box>
                                    <CustomersTopComponent customer={customer} setCustomer={setCustomer}/>
                                    <Box sx={{marginTop: '10px'}}>
                                        <Typography variant="soft" sx={{marginBottom: '5px', width: '100%'}}>Past
                                            Updates</Typography>
                                        <Box sx={{
                                            border: '1px solid #CDD7E1',
                                            borderRadius: '6px',
                                            maxHeight: '150px',
                                            overflowY: 'scroll',
                                            padding: '10px'
                                        }}>
                                            {lead.status.length > 0 ? (
                                                lead.status.map((leadStatus) => (
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        padding: '5px',
                                                        marginBottom: '5px'
                                                    }}>
                                                        <Typography
                                                            level="body-md">{formatDate(leadStatus.created_at)}</Typography>
                                                        <Typography level="body-md">-</Typography>
                                                        <Typography level="body-md">{leadStatus.status}</Typography>
                                                        <Typography level="body-md">-</Typography>
                                                        <Typography level="body-md">{leadStatus.user}</Typography>
                                                    </div>
                                                ))
                                            ) : (
                                                <Typography>No Status Updates Found</Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box sx={{marginTop: '10px'}}>
                                        <Typography variant="soft" sx={{marginBottom: '5px', width: '100%'}}>Selected
                                            Services</Typography>
                                        <Box sx={{display: "flex"}}>

                                            <Box sx={{
                                                minHeight: '100%',
                                                display: "flex",
                                                flexDirection: "row",
                                                minWidth: '100%',
                                            }}>
                                                <Box sx={{minWidth: '50%', marginRight: "15px"}}>
                                                    <List
                                                        sx={{
                                                            '--List-gap': '0.5rem',
                                                            '--ListItem-paddingY': '1rem',
                                                            '--ListItem-radius': '8px',
                                                            '--ListItemDecorator-size': '32px',
                                                        }}
                                                    >
                                                        <ListItem variant="outlined" key="Window Clean"
                                                                  sx={{boxShadow: 'sm'}}>
                                                            <ListItemDecorator>
                                                                <Window/>
                                                            </ListItemDecorator>
                                                            <RadioGroup sx={{width: '100%'}}>
                                                                <Radio
                                                                    overlay
                                                                    checked={leadValue.windowClean}
                                                                    value="Window Clean"
                                                                    label="Window Clean"
                                                                    onClick={(event) => {
                                                                        setLeadValue(prevLead => ({
                                                                            ...prevLead,
                                                                            windowClean: !leadValue.windowClean
                                                                        }));
                                                                    }}
                                                                    sx={{flexGrow: 1, flexDirection: 'row-reverse'}}
                                                                    slotProps={{
                                                                        action: ({checked}) => ({
                                                                            sx: (theme) => ({
                                                                                ...(checked && {
                                                                                    inset: -1,
                                                                                    border: '2px solid',
                                                                                    borderColor: theme.vars.palette.primary[500],
                                                                                }),
                                                                            }),
                                                                        }),
                                                                    }}
                                                                />
                                                            </RadioGroup>
                                                        </ListItem>
                                                        <ListItem variant="outlined" key="Gutter Clean"
                                                                  sx={{boxShadow: 'sm'}}>
                                                            <ListItemDecorator>
                                                                <CleaningServices/>
                                                            </ListItemDecorator>
                                                            <RadioGroup sx={{width: '100%'}}>
                                                                <Radio
                                                                    overlay
                                                                    checked={leadValue.gutterClean}
                                                                    value="Gutter Clean"
                                                                    label="Gutter Clean"
                                                                    onClick={(event) => {
                                                                        setLeadValue(prevLead => ({
                                                                            ...prevLead,
                                                                            gutterClean: !leadValue.gutterClean
                                                                        }));
                                                                    }}
                                                                    sx={{flexGrow: 1, flexDirection: 'row-reverse'}}
                                                                    slotProps={{
                                                                        action: ({checked}) => ({
                                                                            sx: (theme) => ({
                                                                                ...(checked && {
                                                                                    inset: -1,
                                                                                    border: '2px solid',
                                                                                    borderColor: theme.vars.palette.primary[500],
                                                                                }),
                                                                            }),
                                                                        }),
                                                                    }}
                                                                />
                                                            </RadioGroup>
                                                        </ListItem>
                                                        <ListItem variant="outlined" key="Conservatory Gutter Clean"
                                                                  sx={{boxShadow: 'sm'}}>
                                                            <ListItemDecorator>
                                                                <CleanHands/>
                                                            </ListItemDecorator>
                                                            <RadioGroup sx={{width: '100%'}}>
                                                                <Radio
                                                                    overlay
                                                                    checked={leadValue.conservatoryGutterClean}
                                                                    value="Conservatory Gutter Clean"
                                                                    label="Conservatory Gutter Clean"
                                                                    onClick={(event) => {
                                                                        setLeadValue(prevLead => ({
                                                                            ...prevLead,
                                                                            conservatoryGutterClean: !leadValue.conservatoryGutterClean
                                                                        }));
                                                                    }}
                                                                    sx={{flexGrow: 1, flexDirection: 'row-reverse'}}
                                                                    slotProps={{
                                                                        action: ({checked}) => ({
                                                                            sx: (theme) => ({
                                                                                ...(checked && {
                                                                                    inset: -1,
                                                                                    border: '2px solid',
                                                                                    borderColor: theme.vars.palette.primary[500],
                                                                                }),
                                                                            }),
                                                                        }),
                                                                    }}
                                                                />
                                                            </RadioGroup>
                                                        </ListItem>

                                                    </List>
                                                </Box>
                                                <Box sx={{
                                                    minWidth: '50%',
                                                    marginBottom: "5px",
                                                }}>
                                                    <Box>
                                                        <ListItem sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-start'
                                                        }}>
                                                            <Typography>
                                                                Window Cleaning Price
                                                            </Typography>

                                                            <Input
                                                                startDecorator={'£'}
                                                                sx={{maxWidth: '200px'}}
                                                                type="number"
                                                                name={'windowCleanPrice'}
                                                                value={leadValue.windowClean ? leadValue.windowCleanPrice.toString() : '0'}
                                                                onChange={handleLeadValueInputChange}
                                                                disabled={!leadValue.windowClean}
                                                            />
                                                        </ListItem>
                                                        <ListItem sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-start'
                                                        }}>
                                                            <Typography>
                                                                Gutter Cleaning Price
                                                            </Typography>

                                                            <Input
                                                                startDecorator={'£'}
                                                                sx={{maxWidth: '200px'}}
                                                                type="number"
                                                                name={'gutterCleanPrice'}
                                                                value={leadValue.gutterClean ? leadValue.gutterCleanPrice.toString() : '0'}
                                                                onChange={handleLeadValueInputChange}
                                                                disabled={!leadValue.gutterClean}
                                                            />
                                                        </ListItem>
                                                        <ListItem sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-start'
                                                        }}>
                                                            <Typography>
                                                                Conservatory Gutter Cleaning Price
                                                            </Typography>

                                                            <Input
                                                                startDecorator={'£'}
                                                                sx={{maxWidth: '200px'}}
                                                                type="number"
                                                                name={'conservatoryGutterCleanPrice'}
                                                                value={leadValue.conservatoryGutterClean ? leadValue.conservatoryGutterCleanPrice.toString() : '0'}
                                                                onChange={handleLeadValueInputChange}
                                                                disabled={!leadValue.conservatoryGutterClean}
                                                            />
                                                        </ListItem>
                                                    </Box>
                                                    <Typography level="title-md">
                                                        Total Price: £{leadValue.price}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>


                                    </Box>
                                </Box>

                                <Box sx={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                    <Dropdown>
                                        <MenuButton sx={{marginRight: '10px'}}>Change Lead Status</MenuButton>
                                        <Menu sx={{zIndex: 7700}}>
                                            <MenuItem onClick={(event) => setStatus('Opt Out')} sx={{zIndex: 7700}}>Opt
                                                Out</MenuItem>
                                            <MenuItem onClick={(event) => setStatus('No Answer')} sx={{zIndex: 7700}}>No
                                                Answer</MenuItem>
                                            <MenuItem onClick={(event) => setStatus('Call Back')}
                                                      sx={{zIndex: 7700}}>Call Back</MenuItem>
                                        </Menu>
                                    </Dropdown>


                                    {leadId !== -1 && (
                                        <>
                                            <Button
                                                color="danger"
                                                endDecorator={<DeleteForever/>}
                                                onClick={() => setOpenDeleteModal(true)}
                                            >
                                                Delete
                                            </Button>
                                            <Button sx={{marginLeft: '10px'}} color="success" type="submit">Save
                                                Lead
                                            </Button>
                                        </>

                                    )}
                                    {leadId === -1 && (
                                        <Button sx={{marginLeft: '10px'}} color="success" type="submit">Create New
                                            Lead</Button>
                                    )}
                                    <Button onClick={() => {
                                        convertToBooking()
                                    }} sx={{marginLeft: '10px'}}>Convert To
                                        Booking</Button>
                                </Box>
                            </>
                        }
                    </form>
                </ModalDialog>
            </Modal>
            <React.Fragment>
                <ConfirmDeleteModal discardMessage={"Are you sure you want to delete this booking?"}
                                    openState={openDeleteModal}
                                    parentCallback={handleDeleteCallBack}></ConfirmDeleteModal>
                <BookingsModal key={'bookings-modal-' + openBookingModal} openState={openBookingModal} newBooking={lead}
                               newBookingValue={leadValue}
                               bookingId={-1} parentCallback={handleCallback}/>
            </React.Fragment>
        </>
    )
}