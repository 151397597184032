import * as React from 'react';
import Box from '@mui/joy/Box';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, {ListItemButtonProps} from '@mui/joy/ListItemButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import {toggleMessagesPane} from "./MessageUtils";


function formatDate(old_date) {
    let date = new Date(old_date);

    if (!isNaN(date.getTime())) {
        const now: Date = new Date();
        const diffInSeconds: number = Math.floor((now.getTime() - date.getTime()) / 1000);
        let timeAgo: string;
        if (diffInSeconds < 60) {
            timeAgo = `${diffInSeconds} second${diffInSeconds !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 3600) { // less than an hour
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            timeAgo = `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 86400) { // less than a day
            const diffInHours = Math.floor(diffInSeconds / 3600);
            timeAgo = `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 604800) { // less than a week
            const diffInDays = Math.floor(diffInSeconds / 86400);
            timeAgo = `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 2628000) { // less than a month
            const diffInWeeks = Math.floor(diffInSeconds / 604800);
            timeAgo = `${diffInWeeks} week${diffInWeeks !== 1 ? 's' : ''} ago`;
        } else if (diffInSeconds < 31536000) { // less than a year
            const diffInMonths = Math.floor(diffInSeconds / 2628000);
            timeAgo = `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
        } else { // more than a year
            const diffInYears = Math.floor(diffInSeconds / 31536000);
            timeAgo = `${diffInYears} year${diffInYears !== 1 ? 's' : ''} ago`;
        }

        return `${timeAgo}`;
    } else {
        return 'No Valid Date';
    }
}

type ChatListItemProps = ListItemButtonProps & {
    id: string;
    unread?: boolean;
    sender: UserProps;
    messages: MessageProps[];
    selectedChatId?: string;
    setSelectedChat: (chat: ChatProps) => void;
};

export default function ChatListItem(props: ChatListItemProps) {
    const {id, sender, messages, selectedChatId, setSelectedChat} = props;
    const selected = selectedChatId === id;
    const latestMessage = messages.reduce((latest, current) =>
        new Date(current.timestamp).getTime() > new Date(latest.timestamp).getTime() ? current : latest
    );
    const latestMessageCustomer = messages.filter((message) => message.sender.isCustomer).reduce((latest, current) =>
        new Date(current.timestamp).getTime() > new Date(latest.timestamp).getTime() ? current : latest
    );
    return (
        <React.Fragment>
            <ListItem>
                <ListItemButton
                    onClick={() => {
                        toggleMessagesPane();
                        setSelectedChat({id, sender, messages});
                    }}
                    selected={selected}
                    color="neutral"
                    sx={{flexDirection: 'column', alignItems: 'initial', gap: 1}}
                >
                    <Stack direction="row" spacing={1.5}>
                        <Box sx={{flex: 1}}>
                            <Typography level="title-sm">{sender.name}</Typography>
                            <Typography level="body-sm">{sender.phone}</Typography>
                        </Box>
                        <Box sx={{lineHeight: 1.5, textAlign: 'right'}}>
                            {latestMessageCustomer.unread && (
                                <CircleIcon sx={{fontSize: 14}} color="primary"/>
                            )}
                            <Typography
                                level="body-xs"
                                noWrap
                                sx={{display: {xs: 'none', md: 'block'}}}
                            >

                                {formatDate(latestMessage.timestamp)}
                            </Typography>
                        </Box>
                    </Stack>
                    <Typography
                        level="body-sm"
                        sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {latestMessage.sender.isCustomer ? (
                            <>{latestMessage.content}</>
                        ) : (
                            <>{latestMessage.sender.name}: {latestMessage.content}</>
                        )}
                    </Typography>
                </ListItemButton>
            </ListItem>
            <ListDivider sx={{margin: 0}}/>
        </React.Fragment>
    );
}