import React, {useEffect, useRef} from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import {Select, Textarea, FormHelperText} from "@mui/joy";
import Option from "@mui/joy/Option";
import {GetAllCustomers} from "../../axios/Bookings-Axios";
import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import ListItemContent from "@mui/joy/ListItemContent";
import {GetAllFranchisePostcodes} from "../../axios/Axios";
import Tooltip from '@mui/joy/Tooltip';
import {InfoOutlined, InfoRounded} from "@mui/icons-material";

export default function CustomersTopComponent({customer, setCustomer}) {
    const [newCustomer, setNewCustomer] = React.useState<boolean>(false);
    const [invalidPostcode, setInvalidPostcode] = React.useState<boolean>(false);
    const [searchedCustomers, setSearchedCustomers] = React.useState<Customer[]>([])
    const [validOutwardPostcodes, setValidOutwardPostcodes] = React.useState<string[]>([])
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const isPostcodeValid = (outwardPostcode: string): boolean => {
        const regex = /(\S*)\s*\d\w\w$/; // The regex pattern
        const match = outwardPostcode.match(regex);
        return match !== null && validOutwardPostcodes.includes(match[1])
    };

    useEffect(() => {
        if (customer.id == 0) {
            setNewCustomer(true)
        }

        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_');
            let franchise_postcodes = await GetAllFranchisePostcodes(location);
            setValidOutwardPostcodes(franchise_postcodes.data.map((franchise_postcodes) => {
                return franchise_postcodes.outward_postcode
            }))

        }
        fetchData()
    }, [])

    const searchCustomers = (event, value) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            const fetchData = async () => {
                let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_');
                let customers = await GetAllCustomers([location], -1, value);

                setSearchedCustomers(customers);
            };
            fetchData();
        }, 300);
    };

    const handleCustomerInputChange = (event) => {
        const {name, value} = event.target;
        setCustomer(prevCustomer => ({
            ...(prevCustomer || {}),
            [name]: value
        }));

        if (name == 'postcode') {
            setInvalidPostcode(!isPostcodeValid(value))
        }
    };

    const handleCustomerHouseTypeChange = (newValue: string | null) => {
        if (newValue !== null) {
            setCustomer(prevCustomer => ({
                ...prevCustomer,
                house_type: {name: newValue, id: -1}
            }));
        }
    };

    function clearCustomer() {
        setNewCustomer(false)
        let emptyCustomer = {
            advertisement_platform: undefined,
            created_at: "",
            email: "",
            full_name: "",
            house_type: {name: 'Detached', id: -1},
            id: 0,
            notes: "",
            phone: "",
            postcode: "",
            street_address: ""
        }
        setCustomer(emptyCustomer)
    }

    function selectNewCustomer(event, selectedValue) {
        setNewCustomer(false)
        setCustomer(selectedValue)
    }

    return (
        <Box>
            <Typography level="title-lg">Customer Information</Typography>
            {newCustomer ? (
                <Box sx={{width: '100%', marginTop: '10px'}}>
                    <FormLabel>Search For An Existing Customer</FormLabel>
                    <Autocomplete
                        renderOption={(props, option) => (
                            <AutocompleteOption {...props}>
                                <ListItemContent sx={{fontSize: 'sm'}}>
                                    {option.full_name}
                                    <Typography level="body-xs">
                                        ({option.street_address}) {option.phone}
                                    </Typography>
                                </ListItemContent>
                            </AutocompleteOption>
                        )}
                        required
                        getOptionLabel={(option: Customer) => option.full_name}
                        onInputChange={searchCustomers}
                        placeholder="Search by name, street, phone or email"
                        isOptionEqualToValue={(option, value) => option.phone === value?.phone}
                        options={searchedCustomers}
                        onChange={selectNewCustomer}
                    />
                    <Typography onClick={clearCustomer}
                                sx={{cursor: 'pointer', marginTop: 1, marginBottom: 1, textDecoration: 'underline'}}
                                color="primary">Click
                        here to create a NEW
                        customer</Typography>
                </Box>
            ) : (

                <>
                    {customer.full_name != '' && (

                        <Typography>Editing Customer: <Typography
                            fontWeight={500}>{customer.full_name}</Typography></Typography>
                    )}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: '5px',
                    }}>
                        <Box sx={{width: '48%'}}>
                            <FormLabel>Name</FormLabel>
                            <Input
                                required
                                name="full_name"
                                value={customer?.full_name}
                                onChange={handleCustomerInputChange}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{width: '48%'}}>
                            <FormLabel>E-Mail</FormLabel>
                            <Input
                                required
                                name="email"
                                value={customer?.email}
                                onChange={handleCustomerInputChange}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: '5px',
                    }}>
                        <Box sx={{width: '48%'}}>
                            <FormLabel>House Type</FormLabel>
                            <Select
                                required
                                name="house_type"
                                value={customer?.house_type?.name}
                                onChange={(e, newValue) => {
                                    handleCustomerHouseTypeChange(newValue);
                                }}
                                variant="outlined"
                            >
                                <Option value={"Detached"}>Detached</Option>
                                <Option value={"Semi Detached"}>Semi-Detached</Option>
                                <Option value={"Terrace"}>Terrace</Option>
                                <Option value={"Bungalow"}>Bungalow</Option>
                                <Option value={"Ground Floor Apartment"}>Ground Floor Apartment</Option>
                                <Option value={"1st Floor Apartment"}>1st Floor Apartment</Option>
                                <Option value={"2nd Floor Apartment"}>2nd Floor Apartment</Option>
                                <Option value={"3rd Floor Apartment"}>3rd Floor Apartment</Option>
                            </Select>
                        </Box>
                        <Box sx={{width: '48%'}}>
                            <FormLabel>Phone</FormLabel>
                            <Input
                                required
                                name="phone"
                                value={customer?.phone}
                                onChange={handleCustomerInputChange}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: '5px',
                    }}>
                        <Box sx={{width: '100%'}}>
                            <FormLabel>Street Address</FormLabel>
                            <Input
                                required
                                name="street_address"
                                value={customer?.street_address}
                                onChange={handleCustomerInputChange}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: '5px',
                    }}>
                        <Box sx={{width: '100%'}}>
                            <FormLabel>Postcode</FormLabel>
                            <Input
                                required
                                name="postcode"
                                value={customer?.postcode}
                                onChange={handleCustomerInputChange}
                                variant="outlined"
                            />
                            {invalidPostcode && (
                                <>
                                    <Typography sx={{marginTop: '5px', marginBottom: '5px'}} startDecorator={<Tooltip
                                        title="The postcode you have entered is not a valid postcode for this franchise. Please make sure the postcode is located in the same place as the franchise."><InfoOutlined/></Tooltip>}
                                                color="danger">This postcode is not valid for this
                                        franchise.</Typography>
                                </>

                            )}
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: '5px',
                    }}>
                        <Box sx={{width: '100%'}}>
                            <FormLabel>Customer Notes</FormLabel>
                            <Textarea
                                name="notes"
                                value={String(customer?.notes)}
                                onChange={handleCustomerInputChange}
                                variant="outlined"
                                minRows={4}
                                maxRows={4}
                            />
                        </Box>

                    </Box>
                </>

            )}

        </Box>
    )

}