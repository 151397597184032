import React, {useEffect} from "react";
import {
    CircularProgress,
    ListItemDecorator,
    Modal,
    ModalClose,
    ModalDialog,
    Radio,
    RadioGroup,
    Select,
    Textarea
} from "@mui/joy";
import Box from "@mui/joy/Box";
import {
    CreateBooking, DeleteBookingById, DeleteLeadById,
    GetAllBookings,
    GetAllServicePrices,
    GetBookingById, GetRecurringBookingByBookingId,
    UpdateBooking
} from "../../axios/Bookings-Axios";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import {CleanHands, CleaningServices, DeleteForever, InfoOutlined, InfoRounded, Window} from "@mui/icons-material";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {toFormData} from "axios";
import Tooltip from "@mui/joy/Tooltip";
import Button from "@mui/joy/Button";
import TextField from "@mui/joy/TextField";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import CustomersTopComponent from "./CustomersTopComponent";


function formatDate(old_date) {
    let date = new Date(old_date);

    if (!isNaN(date.getTime())) {
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    } else {
        return 'No Valid Date';
    }
}

const formatServices = (services: Service[]): string => {
    const serviceNames = services.map(service => service.name);

    if (serviceNames.length === 0) {
        return "No service selected.";
    } else if (serviceNames.length === 1) {
        return serviceNames[0];
    } else if (serviceNames.length === 2) {
        return `${serviceNames[0]} and ${serviceNames[1]}`;
    } else {
        const lastService = serviceNames.pop();
        return `${serviceNames.join(', ')}, and ${lastService}`;
    }
}

export default function BookingsModal({openState, bookingId, parentCallback, newBooking, newBookingValue}) {
    const [open, setOpen] = React.useState(openState);
    const [loadedData, setLoadedData] = React.useState(false);
    const [loadedOtherBookings, setLoadedOtherBookings] = React.useState(false);

    const [otherBookings, setOtherBookings] = React.useState<Booking[]>([]);

    const [customer, setCustomer] = React.useState <Customer>({
        advertisement_platform: undefined,
        created_at: "",
        email: "",
        full_name: "",
        house_type: {name: 'Detached', id: 1},
        id: 0,
        notes: "",
        phone: "",
        postcode: "",
        street_address: ""
    });
    const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

    const [booking, setBooking] = React.useState <Booking>({
        booked_in: new Date().toISOString(),
        created_at: new Date().toISOString(),
        customer: customer,
        id: -1,
        is_recurring: false,
        invoice_id: '',
        notes: '',
        paid: false,
        payment_type: undefined,
        services: [],
        service_price: [],
        recurring_booking: null
    });
    const [bookingValue, setBookingValue] = React.useState <ServiceValue>({
        conservatoryGutterCleanPrice: 0, gutterCleanPrice: 0, windowCleanPrice: 0,
        conservatoryGutterClean: false,
        gutterClean: false,
        price: 0,
        windowClean: false
    })

    const [recurringBookings, setRecurringBookings] = React.useState <RecurringBooking[]>([])

    function addRecurringBooking(service: string, interval: string, frequency: string) {
        const newBooking: RecurringBooking = {
            created_at: "", start_date: null, id: 0,
            service: {
                id: 1,
                name: service,
            },
            interval: interval,
            frequency: frequency,
            active: true,
            customer: customer,
            service_price: null
        }
        setRecurringBookings((prevBookings) => {
            const updatedBookings = prevBookings.filter((booking) => booking.service.name !== service);
            return [...updatedBookings, newBooking];
        });

    }

    let handleDeleteCallBack = async (doDelete) => {
        setOpenDeleteModal(false)
        if (!doDelete) {
            return;
        }
        const deleteBooking = async () => {
            return await DeleteBookingById(bookingId);
        }
        await deleteBooking();
        window.location.reload(false);
    };


    function removeRecurringBooking(service: string) {
        setRecurringBookings((prevBookings) =>
            prevBookings.filter((booking) => booking.service.name !== service)
        );
    }


    useEffect(() => {
        setBooking(prevBooking => ({
            ...(prevBooking || {}),
            customer: customer
        }));
    }, [customer]);

    useEffect(() => {
        if (!bookingValue.windowClean) {
            removeRecurringBooking('Window Clean')
        }
        if (!bookingValue.conservatoryGutterClean) {
            removeRecurringBooking('Conservatory Gutter Clean')
        }
        if (!bookingValue.gutterClean) {
            removeRecurringBooking('Gutter Clean')
        }
        const newPrice = (bookingValue.windowClean ? Number(bookingValue.windowCleanPrice) : 0) +
            (bookingValue.gutterClean ? Number(bookingValue.gutterCleanPrice) : 0) +
            (bookingValue.conservatoryGutterClean ? Number(bookingValue.conservatoryGutterCleanPrice) : 0);


        setBookingValue(bookingValue => ({
            ...bookingValue,
            price: newPrice
        }));
    }, [bookingValue.windowClean, bookingValue.gutterClean, bookingValue.conservatoryGutterClean, bookingValue.windowCleanPrice, bookingValue.gutterCleanPrice, bookingValue.conservatoryGutterCleanPrice]);


    useEffect(() => {
        setLoadedOtherBookings(false)

        const fetchData = async () => {
            let bookedIn = new Date(String(booking?.booked_in));
            if (bookedIn && booking.customer?.franchise_postcode?.franchise?.location) {
                let day = bookedIn.getDate().toString().padStart(2, '0');
                let month = (bookedIn.getMonth() + 1).toString().padStart(2, '0');
                let year = bookedIn.getFullYear().toString();
                let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
                let bookingResponse = await GetAllBookings([location], 1, null, `${year}-${month}-${day}`);
                if (bookingResponse && bookingResponse['results'].length > 0) {
                    setOtherBookings(bookingResponse['results'])
                } else {
                    setOtherBookings([])
                }
            }
            setLoadedOtherBookings(true)
        }
        fetchData()


    }, [booking.booked_in])


    const handleAddRecurringBooking = (newValue: string | null, name) => {
        if (newValue === 'one-time') {
            removeRecurringBooking(name)
        } else {
            if (newValue !== null) {
                let interval = newValue.split(' ')[0]
                let frequency = newValue.split(' ')[1]
                addRecurringBooking(name, interval, frequency)
            }
        }
    };


    const handleDefaultValue = (name) => {
        let value = 'one-time'
        recurringBookings.forEach((recurringBooking) => {
            if (recurringBooking.service.name === name) {
                value = recurringBooking.interval + ' ' + recurringBooking.frequency
            }
        })
        return value;
    };

    const handleColor = (time) => {
        return time.getHours() > 12 ? "" : "text-error";
    };

    const handleBookingValueInputChange = (event) => {
        const {name, value} = event.target;
        setBookingValue(prevBooking => ({
            ...prevBooking,
            [name]: value,
        }));
    };

    useEffect(() => {
        setBooking(prevBooking => ({
            ...prevBooking,
            customer: customer,
        }));
    }, [customer]);


    useEffect(() => {
        if (newBooking != null && newBooking.id === -1 && bookingId === -1) {
            return;
        }
        if (bookingId === 0) {
            return;
        }
        setLoadedData(false)
        const fetchData = async () => {
            if (newBooking !== null && newBooking != -1) {
                if (!('notes' in newBooking['customer'])) {
                    newBooking['customer']['notes'] = ''
                }
                setCustomer(newBooking['customer'])
                const servicePriceTier = await GetAllServicePrices('D', newBooking['customer']['house_type']);
                const defaultWindowCleanPrice = newBooking?.customer?.franchise_postcode?.service_price_tier?.window_clean_price
                    || servicePriceTier.window_clean_price;

                const defaultConservatoryGutterCleanPrice = newBooking?.customer?.franchise_postcode?.service_price_tier?.conservatory_gutter_clean_price
                    || servicePriceTier.conservatory_gutter_clean_price;

                const defaultGutterCleanPrice = newBooking?.customer?.franchise_postcode?.service_price_tier?.gutter_clean_price
                    || servicePriceTier.gutter_clean_price;

                setBookingValue(prevBooking => ({
                    ...prevBooking,
                    gutterClean: newBooking.services.some(service => service === "Gutter Clean"),
                    windowClean: newBooking.services.some(service => service === "Window Clean"),
                    conservatoryGutterClean: newBooking.services.some(service => service === "Conservatory Gutter Clean"),
                    conservatoryGutterCleanPrice: newBookingValue.conservatoryGutterCleanPrice || defaultConservatoryGutterCleanPrice,
                    gutterCleanPrice: newBookingValue.gutterCleanPrice || defaultGutterCleanPrice,
                    windowCleanPrice: newBookingValue.windowCleanPrice || defaultWindowCleanPrice,
                }));

                setLoadedData(true)
                return;
            }
            const servicePriceTier = await GetAllServicePrices('D', 'Detached');
            if (bookingId !== -1) {
                let recurringBookings = await GetRecurringBookingByBookingId(bookingId);
                setRecurringBookings(recurringBookings)
                let bookings = await GetBookingById(bookingId);
                if (bookings['customer']['notes'] == null) {
                    bookings['customer']['notes'] = ''
                }
                setCustomer(bookings['customer'])
                setBooking(bookings)

                const defaultWindowCleanPrice = bookings?.customer?.franchise_postcode?.service_price_tier?.window_clean_price
                    || servicePriceTier[0].window_clean_price;

                const defaultConservatoryGutterCleanPrice = bookings?.customer?.franchise_postcode?.service_price_tier?.conservatory_gutter_clean_price
                    || servicePriceTier[0].conservatory_gutter_clean_price;

                const defaultGutterCleanPrice = bookings?.customer?.franchise_postcode?.service_price_tier?.gutter_clean_price
                    || servicePriceTier[0].gutter_clean_price;
                setBookingValue(prevBooking => ({
                    ...prevBooking,
                    gutterClean: bookings.services.some(service => service.name === "Gutter Clean"),
                    windowClean: bookings.services.some(service => service.name === "Window Clean"),
                    conservatoryGutterClean: bookings.services.some(service => service.name === "Conservatory Gutter Clean"),
                    conservatoryGutterCleanPrice: bookings.service_price?.find(item => item.service.name === "Conservatory Gutter Clean")?.price || defaultConservatoryGutterCleanPrice,
                    gutterCleanPrice: bookings.service_price?.find(item => item.service.name === "Gutter Clean")?.price || defaultGutterCleanPrice,
                    windowCleanPrice: bookings.service_price?.find(item => item.service.name === "Window Clean")?.price || defaultWindowCleanPrice,
                }));
            } else {
                const defaultWindowCleanPrice = servicePriceTier[0].window_clean_price;
                const defaultConservatoryGutterCleanPrice = servicePriceTier[0].conservatory_gutter_clean_price;
                const defaultGutterCleanPrice = servicePriceTier[0].gutter_clean_price;
                setBookingValue(prevBooking => ({
                    ...prevBooking,
                    conservatoryGutterCleanPrice: defaultConservatoryGutterCleanPrice,
                    gutterCleanPrice: defaultGutterCleanPrice,
                    windowCleanPrice: defaultWindowCleanPrice,
                }));

            }


            setLoadedData(true)
        };
        fetchData()
    }, []);


    function closeModal() {
        console.log('closing')
        setOpen(false)
        parentCallback();
    }

    const handleSave = async (event) => {
        event.preventDefault()
        const updateBooking = async () => {
            if (booking.id !== -1) {
                let createdBooking = await UpdateBooking(bookingId, booking, bookingValue, recurringBookings);
                return createdBooking;
            } else {
                let createdBooking = await CreateBooking(booking, bookingValue, recurringBookings);
                return createdBooking;
            }
        };
        await updateBooking();
        window.location.reload(false);
    };


    return (
        <>
            <Modal slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'none',
                        backgroundColor: 'rgb(108 108 108 / 60%)',
                        padding: '10px'
                    },
                },
            }} open={open} onClose={() => closeModal()}>
                <ModalDialog sx={{overflowY: 'scroll'}}>
                    <form onSubmit={handleSave} id="a-form">
                        <ModalClose/>
                        <Box sx={{
                            minWidth: 600,
                            display: 'flex',
                            flexDirection: 'column',
                            overflowX: 'hidden',
                        }}>
                        </Box>

                        {!loadedData ?
                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                minWidth: '50%',
                                height: '100%',
                                justifyContent: 'center'
                            }}>
                                <CircularProgress
                                    size="lg"
                                />
                            </Box>
                            :
                            <Box>
                                <CustomersTopComponent customer={customer} setCustomer={setCustomer}/>

                                <Box sx={{marginTop: '10px'}}>
                                    <Typography variant="soft" sx={{marginBottom: '5px', width: '100%'}}>Services
                                        Selected</Typography>
                                    <Box sx={{display: "flex"}}>

                                        <Box sx={{
                                            minHeight: '100%',
                                            display: "flex",
                                            flexDirection: "row",
                                            minWidth: '100%',
                                        }}>
                                            <Box sx={{minWidth: '50%', marginRight: "15px"}}>
                                                <List
                                                    sx={{
                                                        '--List-gap': '0.5rem',
                                                        '--ListItem-paddingY': '1rem',
                                                        '--ListItem-radius': '8px',
                                                        '--ListItemDecorator-size': '32px',
                                                    }}
                                                >
                                                    <ListItem variant="outlined" key="Window Clean"
                                                              sx={{boxShadow: 'sm'}}>
                                                        <ListItemDecorator>
                                                            <Window/>
                                                        </ListItemDecorator>
                                                        <RadioGroup sx={{width: '100%'}}>
                                                            <Radio
                                                                overlay
                                                                checked={bookingValue.windowClean}
                                                                value="Window Clean"
                                                                label="Window Clean"
                                                                onClick={(event) => {
                                                                    setBookingValue(prevBooking => ({
                                                                        ...prevBooking,
                                                                        windowClean: !bookingValue.windowClean
                                                                    }));
                                                                }}
                                                                sx={{flexGrow: 1, flexDirection: 'row-reverse'}}
                                                                slotProps={{
                                                                    action: ({checked}) => ({
                                                                        sx: (theme) => ({
                                                                            ...(checked && {
                                                                                inset: -1,
                                                                                border: '2px solid',
                                                                                borderColor: theme.vars.palette.primary[500],
                                                                            }),
                                                                        }),
                                                                    }),
                                                                }}
                                                            />
                                                        </RadioGroup>
                                                    </ListItem>
                                                    <ListItem variant="outlined" key="Gutter Clean"
                                                              sx={{boxShadow: 'sm'}}>
                                                        <ListItemDecorator>
                                                            <CleaningServices/>
                                                        </ListItemDecorator>
                                                        <RadioGroup sx={{width: '100%'}}>
                                                            <Radio
                                                                overlay
                                                                checked={bookingValue.gutterClean}
                                                                value="Gutter Clean"
                                                                label="Gutter Clean"
                                                                onClick={(event) => {
                                                                    setBookingValue(prevBooking => ({
                                                                        ...prevBooking,
                                                                        gutterClean: !bookingValue.gutterClean
                                                                    }));
                                                                }}
                                                                sx={{flexGrow: 1, flexDirection: 'row-reverse'}}
                                                                slotProps={{
                                                                    action: ({checked}) => ({
                                                                        sx: (theme) => ({
                                                                            ...(checked && {
                                                                                inset: -1,
                                                                                border: '2px solid',
                                                                                borderColor: theme.vars.palette.primary[500],
                                                                            }),
                                                                        }),
                                                                    }),
                                                                }}
                                                            />
                                                        </RadioGroup>
                                                    </ListItem>
                                                    <ListItem variant="outlined" key="Conservatory Gutter Clean"
                                                              sx={{boxShadow: 'sm'}}>
                                                        <ListItemDecorator>
                                                            <CleanHands/>
                                                        </ListItemDecorator>
                                                        <RadioGroup sx={{width: '100%'}}>
                                                            <Radio
                                                                overlay
                                                                checked={bookingValue.conservatoryGutterClean}
                                                                value="Conservatory Gutter Clean"
                                                                label="Conservatory Gutter Clean"
                                                                onClick={(event) => {
                                                                    setBookingValue(prevBooking => ({
                                                                        ...prevBooking,
                                                                        conservatoryGutterClean: !bookingValue.conservatoryGutterClean
                                                                    }));
                                                                }}
                                                                sx={{flexGrow: 1, flexDirection: 'row-reverse'}}
                                                                slotProps={{
                                                                    action: ({checked}) => ({
                                                                        sx: (theme) => ({
                                                                            ...(checked && {
                                                                                inset: -1,
                                                                                border: '2px solid',
                                                                                borderColor: theme.vars.palette.primary[500],
                                                                            }),
                                                                        }),
                                                                    }),
                                                                }}
                                                            />
                                                        </RadioGroup>
                                                    </ListItem>

                                                </List>
                                            </Box>
                                            <Box sx={{
                                                minWidth: '50%',
                                                marginBottom: "5px",
                                            }}>
                                                <Box>
                                                    <ListItem sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start'
                                                    }}>
                                                        <Typography>
                                                            Window Cleaning Price
                                                        </Typography>

                                                        <Input
                                                            startDecorator={'£'}
                                                            sx={{maxWidth: '200px'}}
                                                            type="number"
                                                            name={'windowCleanPrice'}
                                                            value={bookingValue.windowClean ? bookingValue.windowCleanPrice.toString() : '0'}
                                                            onChange={handleBookingValueInputChange}
                                                            disabled={!bookingValue.windowClean}
                                                        />
                                                    </ListItem>
                                                    <ListItem sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start'
                                                    }}>
                                                        <Typography>
                                                            Gutter Cleaning Price
                                                        </Typography>

                                                        <Input
                                                            startDecorator={'£'}
                                                            sx={{maxWidth: '200px'}}
                                                            type="number"
                                                            name={'gutterCleanPrice'}
                                                            value={bookingValue.gutterClean ? bookingValue.gutterCleanPrice.toString() : '0'}
                                                            onChange={handleBookingValueInputChange}
                                                            disabled={!bookingValue.gutterClean}
                                                        />
                                                    </ListItem>
                                                    <ListItem sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start'
                                                    }}>
                                                        <Typography>
                                                            Conservatory Gutter Cleaning Price
                                                        </Typography>

                                                        <Input
                                                            startDecorator={'£'}
                                                            sx={{maxWidth: '200px'}}
                                                            type="number"
                                                            name={'conservatoryGutterCleanPrice'}
                                                            value={bookingValue.conservatoryGutterClean ? bookingValue.conservatoryGutterCleanPrice.toString() : '0'}
                                                            onChange={handleBookingValueInputChange}
                                                            disabled={!bookingValue.conservatoryGutterClean}
                                                        />
                                                    </ListItem>
                                                </Box>
                                                <Typography level="title-md">
                                                    Total Price: £{bookingValue.price}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>


                                </Box>

                                {(bookingValue.windowClean || bookingValue.gutterClean || bookingValue.conservatoryGutterClean) && (
                                    <Box sx={{marginTop: '10px'}}>
                                        <Typography
                                            startDecorator={<Tooltip
                                                title="Recurring Bookings will be created a year in advance"><InfoOutlined/></Tooltip>}
                                            variant="soft"
                                            sx={{marginBottom: '5px', width: '100%'}}>Recurring
                                            Booking
                                        </Typography>
                                        {bookingValue.windowClean && (
                                            <>
                                                <Typography sx={{marginBottom: '5px', marginTop: '5px', width: '100%'}}>Window
                                                    Clean</Typography>
                                                <Select
                                                    onChange={(e, newValue) => {
                                                        handleAddRecurringBooking(newValue, 'Window Clean');
                                                    }}
                                                    defaultValue={handleDefaultValue('Window Clean')}>
                                                    <Option value="one-time">One Time Booking</Option>
                                                    <Option value="2 week">Every 2 Weeks</Option>
                                                    <Option value="4 week">Every 4 Weeks</Option>
                                                    <Option value="6 week">Every 6 Weeks</Option>
                                                    <Option value="8 week">Every 8 Weeks</Option>
                                                    <Option value="12 week">Every 12 Weeks</Option>
                                                </Select>
                                            </>
                                        )}

                                        {bookingValue.gutterClean && (
                                            <>
                                                <Typography sx={{marginBottom: '5px', marginTop: '5px', width: '100%'}}>Gutter
                                                    Cleaning</Typography>
                                                <Select onChange={(e, newValue) => {
                                                    handleAddRecurringBooking(newValue, 'Gutter Clean');
                                                }} defaultValue={handleDefaultValue('Gutter Clean')}>
                                                    <Option value="one-time">One Time Booking</Option>
                                                    <Option value="3 month">Every 3 Months</Option>
                                                    <Option value="4 month">Every 4 Months</Option>
                                                    <Option value="6 month">Every 6 Months</Option>
                                                    <Option value="12 month">Every 12 Months</Option>
                                                </Select>
                                            </>
                                        )}

                                        {bookingValue.conservatoryGutterClean && (
                                            <>
                                                <Typography sx={{marginBottom: '5px', marginTop: '5px', width: '100%'}}>Conservatory
                                                    Gutter
                                                    Cleaning</Typography>
                                                <Select onChange={(e, newValue) => {
                                                    handleAddRecurringBooking(newValue, 'Conservatory Gutter Clean')
                                                }} defaultValue={handleDefaultValue('Conservatory Gutter Clean')}>
                                                    <Option value="one-time">One Time Booking</Option>
                                                    <Option value="3 month">Every 3 Months</Option>
                                                    <Option value="6 month">Every 4 Months</Option>
                                                    <Option value="12 month">Every 12 Months</Option>
                                                </Select>
                                            </>
                                        )}

                                    </Box>
                                )}


                                <Box sx={{marginTop: '10px'}}>

                                    <Typography variant="soft" sx={{marginBottom: '5px', width: '100%'}}>Booking
                                        Time</Typography>

                                    <Box sx={{display: 'flex', width: '100%', minWidth: '328px'}}>
                                        <DatePicker
                                            showTimeSelect
                                            selected={new Date(String(booking?.booked_in))}
                                            onChange={(date) => {
                                                setBooking(prevBooking => ({
                                                    ...prevBooking,
                                                    booked_in: date.toISOString()
                                                }));
                                            }}
                                            timeClassName={handleColor}
                                            inline
                                        />

                                        {loadedOtherBookings ?
                                            <Box sx={{marginLeft: '10px', maxWidth: '250px'}}>
                                                <Typography>Other Bookings That Day:</Typography>
                                                {otherBookings.length === 0 ? (
                                                    <Typography>No bookings found</Typography>
                                                ) : (
                                                    <List
                                                        sx={{
                                                            borderRadius: 'sm',
                                                            overflowY: 'scroll',
                                                            maxHeight: '200px'
                                                        }}
                                                    >
                                                        {otherBookings.map((booking, index) => (
                                                            <Tooltip key={index}
                                                                     title={formatServices(booking.services)}
                                                                     arrow
                                                                     placement="right">
                                                                <ListItem key={index} sx={{marginBottom: '5px'}}
                                                                          variant="outlined">
                                                                    {booking.customer?.full_name} - {formatDate(new Date(String(booking.booked_in)))}
                                                                </ListItem>
                                                            </Tooltip>

                                                        ))}
                                                    </List>
                                                )}
                                            </Box>
                                            :
                                            <Box sx={{
                                                display: 'flex',
                                                gap: 2,
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                minWidth: '40%',
                                                justifyContent: 'center'
                                            }}>
                                                <CircularProgress
                                                    size="lg"
                                                />
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        }

                        <Box sx={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                            {loadedData && bookingId !== -1 && (
                                <>
                                    <Button
                                        sx={{marginLeft: 'auto'}}
                                        color="danger"
                                        endDecorator={<DeleteForever/>}
                                        onClick={() => setOpenDeleteModal(true)}
                                    >
                                        Delete
                                    </Button>
                                    <Button type="submit" sx={{marginLeft: '10px'}}>Save
                                        Booking</Button>
                                </>

                            )}
                            {loadedData && bookingId === -1 && (
                                <Button type="submit" sx={{marginLeft: 'auto'}}>Create New
                                    Booking</Button>
                            )}
                        </Box>
                    </form>
                </ModalDialog>
            </Modal>
            <React.Fragment>
                <ConfirmDeleteModal discardMessage={"Are you sure you want to delete this booking?"}
                                    openState={openDeleteModal}
                                    parentCallback={handleDeleteCallBack}></ConfirmDeleteModal>
            </React.Fragment>
        </>
    )
}