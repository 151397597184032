import * as React from 'react';
import {
    Breadcrumbs,
    Card, Chip,
    CircularProgress,
    CssBaseline,
    CssVarsProvider,
    Input,
    Link,
    Select,
    Textarea
} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import SideNav from "../dashboard-components/SideNav";
import Header from "../dashboard-components/Header";
import {forwardRef, useEffect} from "react";
import {EmployeeUpdateBooking, GetAllBookings, GetBookingsToday} from "../../axios/Bookings-Axios";
import Option from "@mui/joy/Option";
import FormLabel, {formLabelClasses} from '@mui/joy/FormLabel';
import Button from "@mui/joy/Button";
import Grid from "@mui/joy/Grid";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import {CancelOutlined, CheckCircle, DateRange, WarningAmber, WarningOutlined} from "@mui/icons-material";

interface DatePickerProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    onCLick: (date: Date | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void

}

function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

export default function TodaysBookings() {
    const [loadedData, setLoadingData] = React.useState(false);
    const [bookings, setBookings] = React.useState <Booking[]>([]);
    const [filterDate, setFilterDate] = React.useState<Date>(new Date());

    useEffect(() => {
        setLoadingData(true)
        const fetchData = async () => {
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            let bookings = await GetBookingsToday([location], formatDate(filterDate))
            setBookings(bookings)
            setLoadingData(false)
        };
        fetchData()

    }, [filterDate])
    const handlePaymentTypeChange = (bookingId, value) => {
        if (bookingId && value) {
            const updatedBookings = bookings.map(booking =>
                booking.id === bookingId
                    ? {...booking, payment_type: {name: value, id: -1}}
                    : booking
            );
            setBookings(updatedBookings);
        }
    };

    const handleNotesChange = (bookingId: number) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {value} = event.target;
        const updatedBookings = bookings.map(booking =>
            booking.id === bookingId
                ? {
                    ...booking,
                    customer: booking.customer
                        ? {
                            ...booking.customer,
                            notes: value
                        }
                        : undefined
                }
                : booking
        );
        setBookings(updatedBookings);
    };


    function saveBooking(id: number) {
        const saveBooking = async () => {
            let booking = bookings.find(booking => booking.id === id);
            if (booking) {
                await EmployeeUpdateBooking(booking?.customer?.notes, booking.payment_type, booking.id).then((r) => {
                    toast.success('Saved Booking!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                });
            }
        }
        saveBooking()
    }

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <ToastContainer/>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <SideNav/>
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: {xs: 2, md: 6},
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: {xs: 2, sm: 2, md: 3},
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Breadcrumbs
                            size="sm"
                            aria-label="breadcrumbs"
                            separator={<ChevronRightRoundedIcon/>}
                            sx={{pl: 0}}
                        >
                            <Link
                                underline="none"
                                color="neutral"
                                href="#some-link"
                                aria-label="Home"
                            >
                                <HomeRoundedIcon/>
                            </Link>
                            <Link
                                underline="hover"
                                color="neutral"
                                href="#some-link"
                                fontSize={12}
                                fontWeight={500}
                            >
                                Dashboard
                            </Link>
                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                Todays Bookings
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mb: 1,
                            gap: 1,
                            flexDirection: {xs: 'column', sm: 'row'},
                            alignItems: {xs: 'start', sm: 'center'},
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography level="h2" component="h1">
                            Todays Bookings
                        </Typography>
                    </Box>
                    <Box sx={{width: 'fit-content'}}>
                        <FormLabel>Pick a date</FormLabel>
                        <DatePicker
                            selected={filterDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => {
                                setFilterDate(date);
                            }}
                        />
                    </Box>


                    {loadedData ? (
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            minWidth: '50%',
                            height: '100%',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress
                                size="lg"
                            />
                        </Box>
                    ) : (
                        <Box>
                            {bookings.length === 0 ? (
                                <Typography startDecorator={<WarningAmber/>} level={"title-sm"}
                                            sx={{marginTop: '10px'}}>
                                    No bookings on {formatDate(filterDate)}
                                </Typography>
                            ) : (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginBottom: '100px'}}>
                                    {bookings.map((booking, index) => (
                                        <Card key={index}
                                              sx={{flex: '1 1 calc(50% - 16px)', justifyContent: 'space-between'}}>
                                            <Box>
                                                <Typography level={"title-md"}>
                                                    Name: <Typography
                                                    fontWeight={350}>{booking?.customer?.full_name}</Typography>
                                                </Typography>
                                                <Typography sx={{marginBottom: '0px'}} level={"title-md"}>
                                                    Street Address: <Typography
                                                    fontWeight={350}>{booking?.customer?.street_address}</Typography>
                                                </Typography>
                                                <Typography sx={{marginTop: '0px'}} level={"title-md"}>
                                                    Postcode: <Typography
                                                    fontWeight={350}>{booking?.customer?.postcode}</Typography>
                                                </Typography>
                                                <Typography sx={{marginTop: '0px'}} level={"title-md"}>
                                                    Number: <Typography
                                                    fontWeight={350}>{booking?.customer?.phone}</Typography>
                                                </Typography>
                                                <Typography sx={{marginTop: '0px'}} level={"title-md"}>
                                                    Email: <Typography
                                                    fontWeight={350}>{booking?.customer?.email}</Typography>
                                                </Typography>
                                                <Box sx={{display: 'flex'}}>
                                                    <Typography sx={{marginTop: '0px', marginRight: '5px'}}
                                                                level={"title-md"}>
                                                        Invoice Sent:
                                                    </Typography>

                                                    {booking?.invoice_id ? (
                                                        <Chip color={"success"}>Invoice ID:
                                                            #{booking?.invoice_id}</Chip>
                                                    ) : (
                                                        <Chip color={"danger"}>No</Chip>
                                                    )}
                                                </Box>

                                            </Box>
                                            <Box>
                                                <FormLabel>Payment Type</FormLabel>
                                                <Select
                                                    value={booking.payment_type?.name || ''}
                                                    onChange={(e, newValue) => {
                                                        handlePaymentTypeChange(booking.id, newValue);
                                                    }}
                                                    sx={{background: 'white'}}
                                                >
                                                    <Option value="Cash">Cash</Option>
                                                    <Option value="Card">Card</Option>
                                                    <Option value="Invoice">Invoice</Option>
                                                </Select>
                                                <FormLabel sx={{marginTop: '15px'}}>Notes</FormLabel>
                                                <Textarea
                                                    name="notes"
                                                    value={booking?.customer?.notes || ''}
                                                    onChange={handleNotesChange(booking.id)}
                                                    sx={{background: 'white'}}
                                                    variant="outlined"
                                                    minRows={4}
                                                    maxRows={4}
                                                />
                                                {booking?.payment_type?.name === 'Invoice' ? (
                                                    <Button sx={{width: '100%', marginTop: '15px'}}
                                                            onClick={() => saveBooking(booking?.id)}>
                                                        Mark As Complete & Send Invoice
                                                    </Button>
                                                ) : (
                                                    <Button sx={{width: '100%', marginTop: '15px'}}
                                                            onClick={() => saveBooking(booking?.id)}>
                                                        Mark As Complete
                                                    </Button>
                                                )}

                                            </Box>
                                        </Card>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    )}

                </Box>
            </Box>
        </CssVarsProvider>
    )

}